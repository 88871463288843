import { IButtonLoader } from '@models/button.model';
import Img from './Img';

export const ButtonLoader = ({
  children,
  isLoading = false,
  className,
  ...props
}: IButtonLoader) => {
  return (
    <button
      className={`min-w-[150px] disabled:cursor-not-allowed disabled:opacity-60 ${className}`}
      {...props}
      disabled={isLoading || props.disabled}
    >
      {!isLoading && children}
      {isLoading && (
        <Img
          src={'/Resources/images/loading.gif'}
          style={{ width: '28px' }}
          alt='Loader'
          title='Loader'
        />
      )}
    </button>
  );
};
