export const ENDPOINTS = {
  CREATE_APPLICATION: '/V1/Application',
  GET_APPLICATION: '/V1/Application',
  UPDATE_APPLICATION: '/V1/Application',
  SCHEMES_ENTITTES: '/V1/Schemes/SchemeEntities',
  SCHEME_BUSINESS_VERIFICATION: '/V1/Schemes/SendBusinessVerification',
  SCHEME_BUSINESS_VERIFICATION_RESEND: '/V1/Schemes/ResendBusinessVerification',
  SCHEME_VERIFICATION_BY_PROMO_CODE: '/V1/Schemes/SchemeVerificationByPromoCode',
  SCHEME_VERIFICATION_POLICY_HOLDER: '/V1/Schemes/SchemeVerificationOfPolicyHolder',
  SCHEME_VERIFICATION_VERIFY_EMAIL: '/V1/Schemes/SchemeVerificationByCode',
  UPDATE_DRIVIER_PERCENTAGE: '/V1/Drivers/UpdatePercentage',
  REMOVE_DRIVER: '/V1/Drivers/Delete',
  ADD_POLICY_HOLDER_AS_DRIVER: '/V1/Drivers/CreatePolicyHolderDriver',
  ADD_DRIVER: '/V1/Drivers/Create',
  UPDATE_DRIVER: '/V1/Drivers/Update',
  GET_CAPTCHA: '/V1/Captcha',
  PURPOSE_OF_USE_LOOKUP: '/V1/Lookups/VehiclePurpose',
  VEHICLE_NIGHT_PARKING: '/V1/Lookups/VehicleNightParking',
  VEHICLE_MILE_RANGE: '/V1/Lookups/VehicleMileageRange',
  TRANSMISSION_TYPE: '/V1/Lookups/TransmissionType',
  EDUCATION: '/V1/Lookups/Education',
  CHILDREN_BELOW: '/V1/Lookups/ChildrenBelow',
  ACCIDENTS_COUNT: '/V1/Lookups/AccidentsCount',
  HEALTH_CONDITION: '/V1/Lookups/HealthCondition',
  TRAFFIC_VIOLATION: '/V1/Lookups/TrafficViolation',
  POLICY_HOLDER_NATIONALITY: '/V1/Lookups/PolicyholderNationality',
  LICENSE_OWING_YEARS: '/V1/Lookups/LicenseOwingYears',
  RELATIONSHIPS: '/V1/Lookups/DriverRelation',
  LICENSE_TYPES: '/V1/Lookups/LicenseType',
  OCCUPATION: '/V1/Lookups/Occupation',
  VEHICLE_MAKE: '/V1/Lookups/VehicleMake',
  VEHICLE_MODEL: '/V1/Lookups/VehicleModel',
  OFFERS_GET: '/api/V2/Addons/get',
  OFFERS_BY_PID: '/api/V2/Addons/getByPolicyID',
  CITIES: '/V1/Lookups/Cities',
  SEARCH_ENTITY: '/V2/Scheme/Entities',
  SEARCH_ENTITY_VALID_SCHEMES: '/V2/Scheme/EntityValidSchemes',
  SHARE_QUOTE_LINK: '/V1/ShareQuoteLink',
  PLOICY_HOLDER_DETAILS: '/V1/PolicyHolderAndVehicle',
  QUOTATIONS: '/V1/Quotations',
  SAVED_QUOTES: '/V1/SavedQuotes',
  QUOTATIONS_AVAILIBITY: '/V1/Quotations/Availibility',
  INSURANCE_COMPANY_DETAILS: '/V1/InsuranceCompany/Profile',
  GET_FIRST_STEP: '/V1/Application/GetRequestData',
  REQUOTE: '/V1/Application/Requote/Discrepancy',
  UPDATE_VEHICLE_INFO: '/V1/Application/Requote/UpdateVehicle',
  SELECT_QUOTE: '/api/V1/Application/Agreement',
  VEHICLE_DETAILS: '/V1/Application/VehicleDetail',
  SHARE_QUOTE: '/V1/ShareQuoteUrl',
  HOME_ANNOUNCEMENT: '/V1/Home/GetHomeAnnouncement',
  GET_SEQUENCES_IDS: '/V1/Application/GetSuggestedSequences',
  GET_LOOKUPS_EXPIRATION_DATE: '/V1/Lookups/GetCacheTime',
  GET_PARTNERS: '/V1/Home/GetPartners',
  GET_PARTNERS_NAME: '/V1/Home/GetPartnersNames',
  GET_QITAF_PARTNERS_NAME: '/V1/Home/GetQitafCompanyNames',
  GET_PARTNERS_ALIAS: '/V1/Home/GetPartnerDetails',
  GET_PARTNERS_DETAILS: '/V1/Home/GetPartnerDetails',
  CHECK_POLICIES: '/V1/Home/GetPrintActivePolices',
  CONTACT_US: '/V1/Home/ContactUs',
  SAMA_POLICIES: '/V1/Home/SamaPolices',
  RESEND_OTP: '/V1/ResendOTP',
  SEND_POLICY_BY_EMAIL: '/V1/Home/SendPolicyByEmail',
  GET_RECEIPT_INFO: '/V1/Home/GetReceiptInfo',
  GET_ACTIVE_NAJM_STATUS: '/V1/Home/GetNajmActivePolices',
  UPDATE_NAJM_STATUS_SMS_FLAG: '/V1/Home/UpdateNajmSMSFlag',
  UPDATE_NAJM_STATUS_MOBILE: '/V1/Home/UpdateNajmSMSMobile',
  SELECTED_QUOTE: '/V1/Quote/Select',
  PAY: '/V1/Pay',
  AGREEMENT: '/V1/agreement',
  IBAN_LIST: 'V1/IBANList',
  DELETE_IBAN: 'V1/DeleteIBAN',
  VERIFY_OTP: 'V1/VerifyOTP',
  APPLY_COUPON: 'V1/Coupon/Apply',
  CANCEL_COUPON: 'V1/Coupon/Cancel',
  GENERATE_OTP_QITAF: 'V1/Qitaf/GenerateOTP',
  QITAF_REDEEM: 'V1/Qitaf/Redeem',
  QITAF_EARN: 'V1/Qitaf/Earn',
  QITAF_REVERSE: 'V1/Qitaf/Reverse',
  CANCEL_EARN: 'V1/Qitaf/CancelEarn',
  UPLOAD_IMAGE: 'V1/UploadImage',
  DOWNLOAD_IMAGES: 'V1/DownloadImages',
  SUCCESS_PAYMENT: 'V1/Payment/successDetails',
  PAYMENT_POLICY: 'V1/Payment/GetPolicy',
  UPDATE_CREATE_BY: 'V1/User/Update',
  MOBILE_UNIQUENESS_CHECK: 'V1/MobileUniquenessCheck',
  SHARE_TO_EMAIL: 'V1/ShareToEmail',
  APPLE_PAY_VALIDATE_MERCHANT: 'V1/applepay',
  APPLE_PAY_VALIDATE_PAYMENT: 'V1/applepay/validate',
  GENERATE_TOKEN: 'V1/GenerateToken',
  DW_VP: '/V1/Payment/GetPolicySuccessDetails',
  CHECK_EMAIL_VERIFICATION: '/V1/Schemes/CheckEmailVerification',
  MAIN_FEATURES_LOOKUP: '/V1/Lookups/MainFeatures',
  WALAA_LANDING_OFFERS: '/V1/Walaa/GetWalaaLandingOffers',
  HOME_BANNER: '/V1/Home/Banners',
};
