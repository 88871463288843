export const getEnv = () => {
  const host = window.location.host;
  if (host.includes('localhost')) return 'local';
  else if (host.includes('devmotor')) return 'dev';
  else if (host.includes('motordev')) return 'k8s';
  else if (host.includes('qcmotor')) return 'qc';
  else if (host.includes('pre')) return 'preprod';
  else if (host.includes('.tameeni.com')) return 'prod';
  else return 'local';
};
